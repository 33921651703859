var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"m7K1ZMvWs80K1mOyMof05"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/ilp-public/assets";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN || 'https://3792a2e89ebb405db83178074c494aa5@o46740.ingest.sentry.io/5421017',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_ENV == 'production' ? 0.05 : 0.0, 
  sampleRate: SENTRY_ENV == 'production' ? 0.05 : 0.0, 
  environment: SENTRY_ENV,
  release: process.env.SENTRY_RELEASE || 'not-set'
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
