import Head from 'next/head'
import type { AppProps } from 'next/app'

import '../styles.css'

export default function ({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head
        children={
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
          />
        }
      />
      <Component {...pageProps} />
    </>
  )
}
